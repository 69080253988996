<template>
  <div v-show="messageBox.isShow">
     <el-dialog
        v-dialogDrag
        custom-class="el-dialog-drag"
        :visible.sync="showBox"
        :close-on-click-modal="false"
        :show-close="false"
        :modal="false"
        width="660px"
        :title="$t('setUp.seeting')"

        center
      >
       <setting ref="settingEl" @close="closePanel" @openNetwork="openNetwork" @isBeautyChange="isBeautyChange"  :isMeeting="isMeeting" :myCamera="myCamera" :isShow="messageBox.isShow" :activeDefaultIndex="settingPanelDefaultIndex"></setting>
      </el-dialog>
  </div>
</template>
<script>
import Setting from "@/components/setting/Setting.vue";
import { mapGetters } from 'vuex';
export default {
  components: { Setting },
  data() {
    return {
      messageBox: {
         isShow: false
      },
      showBox: true
    }
  },
  props: {
    isMeeting: { default : false },
    myCamera: { default : false },
  },
  computed: {
    ...mapGetters(["isShowSettingPanel","settingPanelDefaultIndex"])
  },
  watch: {
     isShowSettingPanel(value) {
       this.messageBox.isShow = value
     }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    closePanel() {
      this.messageBox.isShow = false
      this.$store.commit("isShowSettingPanel", false); 
      this.$store.commit("settingPanelDefaultIndex", 0); 
    },
    openNetwork() {
      this.$emit('openNetwork')
    },
    isBeautyChange(val) {
      this.$emit('isBeautyChange',val)
    },
    refreshLocalPreviewOnly(){
      this.$refs.settingEl.refreshLocalPreviewOnly();//重置预览画面
    }
  }
}
</script> 
<style lang="scss" scoped>
  ::v-deep .el-dialog--center .el-dialog__body {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
   ::v-deep .el-dialog__header {
    
   }
</style>