/**
     * 模拟请求
     */

function request(url) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        img.src = url + '?random-no-cache=' + Math.floor((1 + Math.random()) * 0x10000).toString(16);
        img.onload = function() { resolve(img) }
        img.onerror = function() { reject(url) }
    })
}
export function ping(url, multiplier) {
    return new Promise(function(resolve, reject) {
        var start = (new Date()).getTime();
        var response = function() { 
            var delta = ((new Date()).getTime() - start);
            delta *= (multiplier || 1);
            resolve({pingTime: Math.floor(delta)});
        };
        request(url).then(response).catch(() => resolve({pingTime: -1}))
    })
}
