<template>
  <div class="progress-bar" :style="{ 'width': width + 'px'}">
    <div class="particles" :class="{ 'over': index < currentCount }" v-for="(count, index) in particles" :key="index">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        particles: [],
        currentCount: 0
      }
    },
    mounted() {
      const count = Math.floor((this.width + 2) / 8)
      this.particles = new Array(count)
      this.currentCount = Math.floor((this.width * this.percent + 2) / 8)
    },
    watch: {
      percent(value) {
        this.currentCount = Math.floor((this.width * value + 2) / 8)
      }
    },
    props: {
      width: {default: 320}, // 宽度
      percent: {default: 0}, // 进度百分比 0-1
    }
  }
</script>

<style lang="scss" scoped>
  .progress-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 16px;

    .particles {
      width: 6px;
      height: 16px;
      background: #E2E2E2;
      border-radius: 2px;
    }

    .over {
      background: #1AB370;
    }
  }
</style>