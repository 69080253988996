<template>
  <div class="videoBox" :style="{'width': width + widthUnit,'height': height + 'px'}">
    <video disablePictureInPicture :id="id" class="localBoxVideo media-reset-flag"></video>
    <div class="label" v-if="!isCloseDevice">{{$t('setUp.lookIng')}}</div>
    <div class="closeText" v-if="isCloseDevice">{{$t('setUp.closeCamrea')}}</div>
    <div v-if="isEnabldImageReversal && !isCloseDevice" class="imageIcon" @click="switchImageDirection">
      <my-icon v-if="imageDirection" :fontSize="24" :iconName="'iconhuamian_jingxiang_you_24_bai'"></my-icon>
      <my-icon v-else :fontSize="24" :iconName="'iconhuamian_jingxiang_zuo_24_bai'"></my-icon>
    </div>
  </div>
</template>

<script>
export default {
 data() {
   return {
     imageDirection: 0, // 镜像翻转方向
   }
 },
 props: {
   id: { default: "" }, // 视频元素ID
   deviceId: { default: "" }, // 设备ID
   width: { default: 240 }, // 宽度值
   widthUnit: { default: 'px' }, // 宽度单位
   height: { default: 135 }, // 高度
   isEnabldImageReversal: { default: false }, //是否开启镜像翻转
   isCloseDevice: { default: false }, //是否关闭摄像头
   isShow: { default: false }
 },
 methods: {
 }
}
</script>

<style scoped lang="scss">
.videoBox {
 position: relative;
 background: #000;
 margin:0 auto;
 video {
   width: 100%;
   height: 100%;
   opacity: 0.99;
 }
}
@mixin img_style($height){
 height: $height;
 position: absolute;
 z-index: 1;
}
@mixin pub_style($height,$color){
 @include img_style($height);
 font-size: 12px;
 font-family: PingFangSC-Regular, PingFang SC;
 color:$color;
 font-weight: 400;
 line-height: $height;
 text-align: center;
 transform: translateX(-50%);
}
.label {
 @include pub_style(18px,#FFFFFF);
 padding:0 9px;
 bottom: 8px;
 left: 50%;
 background: rgb(0, 0, 0, .2);
 border-radius: 9px;
}
.closeText {
 @include pub_style(16px,#999999);
  width: 100%;
  top: 50%;
}
.imageIcon {
 @include img_style(24px);
  width: 24px;
  background: rgb(0, 0, 0, .3);
  border-radius: 2px;
  top: 8px;
  right: 8px;
  &:hover {
   background: rgb(0, 0, 0, .5);
   cursor: pointer;
   }
   &:active{
     background: rgb(0, 0, 0, .8);
   }
}
</style>